app.service('actions', function (audit, modal, loading, broker, modalPage, $global, uploadImage, console){

	var $button, isModalPage, $form;

	function init(){
		$('body').on('click', '.button-row-action, .button-action', function(){
			clickAction($(this));
		}).on('click', '.js-button-select-all', function(){
			selectAll($(this));
		});
	}

	function clickAction($b){
		$button=$b;
		isModalPage = modalPage.isOpen();
		$form = isModalPage ? $('#modal-edit-form, #modal-list-form') : $('#edit-form, #list-form');
		if ($button.data('confirm-message')) {
			modal.confirm($button.data('confirm-message'), function () { 
				checkAudit();
			});
		} else if ($button.data('alert-message')) {
			modal.alert($button.data('alert-message'), function () {
				checkAudit();
			});
			
		} else if ($button.hasClass('js-add-ajax-photo')) {
			uploadImage.open({
				url: $button.data('ajax'),
				$span: $button.closest('.group-image').find('span.img'),
				data: $button.data('value'),
				callback: $button.data('callback')
			});
		} else {
			checkAudit();
		}
	}
	function checkAudit() {
		if ($button.data('disabled') == true) { 
			console.log('GUZIK ZABLOKOWANY');
			return;
		} else if($button.data('audit')){
			audit.open(function(comment){
				var $input = $('<input type="hidden" name="audit_comment"/>');
				$input.val(comment);
				$input.appendTo($form)
				action();
			},function(){
				if(isModalPage){
					modalPage.open();
				}
			});
		}else{
			action();
		}
	}
	function action(){
		var submit=false;
		if($button.data('post')){
			var $newForm = $('<form method="POST"/>').attr('action',$button.data('post'));
			if($button.data('value')){
				var $input = $('<input type="hidden" name="id" />').val($button.data('value'));
				$newForm.append($input);
			}
			loading.start();
			$newForm.appendTo($('body'));
			if(isModalPage){
				modalPage.setFormAjax($newForm);
			}
			$newForm.submit();
			return;
		}
		if($button.data('ajax')){
			loading.start();
			$.ajax({
				url: $button.data('ajax'),
				type: 'POST',
				data:{
					id: $button.data('value')
				},
				success:function(result){
					loading.stop();
					if(result.status && result.status == 'FAIL'){
						if(result.messages && result.messages.length){
							modal.alert(result.messages[0]);
						}else{
							modal.alert('Backend zwrócił błąd');
						}
					}else if(result.status && result.status == 'SUCCESS'){
						if(isModalPage){
							modalPage.reload()
						}else{
							if($button.data('ajax-redirect')){
								loading.redirect($button.data('ajax-redirect'));
							}else{
								loading.reload();
							}
						}
					}else{
						modal.alert('Niewłaściwe obsłużone żądanie');
					}
				},
				error:function(){
					loading.stop();
					modal.alert('Błąd przetwarzania danych');
				}
			});
			return;
		}
		if($button.data('modal-page')){
			modalPage.load($button.data('modal-page'));
			return;
		}
		if($button.data('ajax-page')){
			ajaxPage.load($button.data('ajax-page'));
			return;
		}
		if($button.data('revision')){
			$('input.revision:hidden', $form).val($button.data('revision'));
		}
		if($button.data('submit')){
			$form.attr('action',$button.data('submit'));
			submit=true;
		}
		let action = $button.is('[data-action]') ? '' + $button.data('action') : false;
		if(action !== false){
			$('input.action:hidden', $form).val(action);
			submit=true;
		}
		let value = $button.is('[data-value]') ? '' + $button.data('value') : false;
		if (value !== false) {
			$('input.selected-item:hidden', $form).val(value);
			submit=true;
		}
		if($button.data('event-id')){
			$('input.event-id:hidden', $form).val($button.data('event-id'));
			submit=true;
		}
		if(submit){
			$form.submit();
		}
	}

	function setAction(params) { // do dalszej rozbudowy
		var $form = $('#edit-form');
		if (!$form.length) {
			$form = $('#list-form');
		}
		$('input.action:hidden', $form).val(params.action);
		$form.submit();
	}
	$global('setAction',setAction);

	function selectAll($button) {
		var selectElement = document.getElementById($button.data('select-id'));
		for (var i = 0; i < selectElement.options.length; i++) {
			selectElement.options[i].selected = true;
		}
	}
	$global('selectAll', selectAll);

	return {
		init,
		setAction,
		selectAll
	}
});
